/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Props, type PartialProps } from "../../system/HewSync";
import { UserAccountID } from "../../system/IDs";


@HewSync.Type({
  "name": "UserAccount",
  "scope": "User",
  "table": "user",
  "type": "User:Account"
})
export class UserAccount extends HewSyncType {
	public static readonly Type = UserAccount as typeof HewSyncType;
	public static override readonly type = "UserAccount";
	public override readonly type = UserAccount.type;

	@HewSync.Field({ })
	public readonly account: UserAccountID;

	@HewSync.Field({ })
	public readonly email: string;

	@HewSync.Field({ })
	public readonly name: string;

	@HewSync.Field({ })
	public readonly lastEmailChangeAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly lastLoginAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly lastPasswordChangeAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly updatedAt: HewSync.Timestamp;


	/********************************************/

	public static get(ids: Pick<UserAccount, UserAccount.IDs>) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new UserAccount({
				...UserAccount.defaults(),
				account: ids.account,
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: Pick<UserAccount, UserAccount.IDs>) {
		return HewSync.get<UserAccount>(UserAccount.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<Pick<UserAccount, UserAccount.IDs>>): HewSyncList<UserAccount> {
		let list = new (HewSyncList as any)(UserAccount.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<Pick<UserAccount, UserAccount.IDs>> & { limit?: number; nextToken?: string; }) {
		return HewSync.list<UserAccount>(UserAccount.Type, inputs);
	}

	public static batchGet(ids: Pick<UserAccount, UserAccount.IDs>[]) {
		return HewSync.batchGet<UserAccount>(UserAccount.Type, ids);
	}

	public static create(values: UserAccount.Create) {
		return HewSync.create<UserAccount>(UserAccount.Type, "create", values);
	}

	public static update(ids: Pick<UserAccount, UserAccount.IDs>, values: Partial<Pick<UserAccount, UserAccount.Modifiable>>, target?: UserAccount) {
		return HewSync.request<UserAccount>(UserAccount.Type, "update", {...values, ...ids}, target);
	}

	public static remove(ids: Pick<UserAccount, UserAccount.IDs>, target?: UserAccount) {
		return HewSync.request<UserAccount>(UserAccount.Type, "remove", ids, target);
	}

	/********************************************/

	public update(values: Partial<Pick<UserAccount, UserAccount.Modifiable>>) {
		return UserAccount.update(this.ids, values, this);
	}

	public remove() {
		return UserAccount.remove(this.ids, this);
	}

	/********************************************/

	private static cache = new Map<string, UserAccount>();

	public get ids() { return { account: this.account }; }

	public static getKey(ids: Pick<UserAccount, UserAccount.IDs>) { return ids.account.toString(); }

	public get key() { return this.account.toString(); }

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).account = data.account;

		this.apply({ ...UserAccount.defaults(), ...data });
	}

	protected override apply(data: Partial<Pick<UserAccount, UserAccount.Fields>>) {
		(this as any).email = data.email;
		(this as any).name = data.name;
		(this as any).lastEmailChangeAt = data.lastEmailChangeAt;
		(this as any).lastLoginAt = data.lastLoginAt;
		(this as any).lastPasswordChangeAt = data.lastPasswordChangeAt;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as UserAccount;
		(values as any).email = "";
		(values as any).name = "";
		(values as any).lastEmailChangeAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).lastLoginAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).lastPasswordChangeAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): PartialProps<UserAccount, UserAccount.IDs | UserAccount.Fields> {
		let output: PartialProps<UserAccount, UserAccount.IDs | UserAccount.Fields> = {};
		if (data.account !== undefined) {
			output.account = new UserAccountID(data.account);
		}
		if (data.email !== undefined) {
			output.email = data.email;
		}
		if (data.name !== undefined) {
			output.name = data.name;
		}
		if (data.lastEmailChangeAt !== undefined) {
			output.lastEmailChangeAt = new HewSync.Timestamp(data.lastEmailChangeAt);
		}
		if (data.lastLoginAt !== undefined) {
			output.lastLoginAt = new HewSync.Timestamp(data.lastLoginAt);
		}
		if (data.lastPasswordChangeAt !== undefined) {
			output.lastPasswordChangeAt = new HewSync.Timestamp(data.lastPasswordChangeAt);
		}
		if (data.createdAt !== undefined) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			account: this.account,
			email: this.email,
			name: this.name,
			lastEmailChangeAt: this.lastEmailChangeAt,
			lastLoginAt: this.lastLoginAt,
			lastPasswordChangeAt: this.lastPasswordChangeAt,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["account"] as const;
	public readonly fieldIDs = UserAccount.fieldIDs;
	public static readonly fields = ["email", "name", "lastEmailChangeAt", "lastLoginAt", "lastPasswordChangeAt", "createdAt", "updatedAt"] as const;
	public readonly fields = UserAccount.fields;
}

export namespace UserAccount {
	export type IDs = "account";
	export type Fields = "email" | "name" | "lastEmailChangeAt" | "lastLoginAt" | "lastPasswordChangeAt" | "createdAt" | "updatedAt";
	export type Modifiable = "email" | "name" | "lastEmailChangeAt" | "lastLoginAt" | "lastPasswordChangeAt";
	export type Create = {
		email: string;
		name: string;
		lastEmailChangeAt: HewSync.Timestamp;
		lastLoginAt: HewSync.Timestamp;
		lastPasswordChangeAt: HewSync.Timestamp;
	}

}
